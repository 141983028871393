<template>
  <v-app>
    <v-navigation-drawer
      right
      v-model="drawer"
      app
      temporary
    >
      <v-list dense>
        <v-list-item v-for="(menu, i) in this.$t('menus')" :key="i" :to="menu.link">
          <v-list-item-action>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat absolute class="app-bar" height="108" max-width="1264">
      <v-toolbar-title class="headline" style="display: flex;align-items: center;">
        <img :src="info.logo" style="height:103px;"/>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
      <v-btn
        v-for="(menu, i) in this.$t('menus')"
        :key= "i"
        :href="menu.link"
        :id="menu.id"
        class="ml-0 hidden-sm-and-down white text-none ctk-menu"
      >
        {{ menu.name }}
      </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="white text-none"
            >
              {{ $t('language') }}<v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(language, index) in languages"
              :key="index"
              @click="changeLanguage(index)"
            >
              <v-list-item-title>{{ language }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
        >
        </v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>
    <v-content>
      <router-view></router-view>
    </v-content>
    <Footer/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer')
  },
  data: () => ({
    afterGetData: false,
    drawer: null,
    languages: ['简体中文', 'English']
  }),
  mounted () {
    this.$store.dispatch('loadInfo')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        // this.info.logo = val.logo
        document.querySelector('meta[name="keywords"]').setAttribute('content', val.seo_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', val.seo_description)
        document.querySelector("link[rel*='icon']").setAttribute('href', val.favicon)
      },
      deep: true
    },
    '$route' (to, from) {
      this.title(this.$route)
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    /**
     * 切换语言
     * @param index
     * @returns {boolean}
     */
    changeLanguage (index) {
      let currentLanguage
      if (index) {
        currentLanguage = 'en'
      } else {
        currentLanguage = 'cn'
      }
      if (currentLanguage === 'en') {
        window.location.href = 'https://www.renolit.com/en'
      }
      /*
      if (localStorage.getItem('locale') === currentLanguage) {
        return false
      }
      localStorage.setItem('locale', currentLanguage)
      this.$i18n.locale = currentLanguage
      window.location.reload()
      */
    },
    /**
     * 路由切换设置title
     * @param to
     */
    title (to) {
      // document.title = to.meta.title + '_' + this.$store.getters.companyInfo.name
    }
  }
}
</script>
<style lang="scss">
  @import 'sass/variables.scss';
  .v-application {
    font-family: $body-font-family !important;
  }
</style>
<style lang="css">
  a {
    text-decoration: none;
  }
  .theme--light {background: #fff !important;}
  .ctk-footer ul {list-style-type: none;padding-left: 0 !important;}
  .ctk-footer a {color: white !important;font-size: 14px;}
  .ctk-footer .ctk-footer-title {color: #999;font-size: 18px;}
  .ctk-footer .ctk-footer-name {font-size: 22px;}
  .ctk-footer span {font-size: 14px !important;}
  .ctk-footer .ctk-footer-link li {float: left; margin-right: 10px;}
  .ctk-footer .ctk-footer-copyright {margin-top: 40px; color: #999;}
  .ctk-video-div {margin-top: 100px;}
  .ctk-video video {width:100%; border:none;margin:0;}
  .ctk-div {padding-top: 80px !important;}
  .ctk-div-title {font-size: 32px; color: #333; margin-bottom:30px; }
  .app-bar { margin:auto; }
  .app-bar .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
    will-change: box-shadow;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .app-bar .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 1;
  }
  .app-bar .v-btn--active:before, .v-btn:focus:before {
    background: none;
    border-bottom: 2px solid #076DB1;
  }
  .app-bar .v-btn--active > .v-btn__content {color:#076DB1;}
  .ctk-home-tab .v-tab--active {background: #076DB1 !important; color: #fff !important; font-size:14px !important; border-radius: 0.2em;}
  .ctk-home-tab .v-tabs-slider {background:none !important;}
  .smallProduct {padding:60px !important;}
  .ctk-home-tab .v-item-group {margin-bottom: 2em !important;}
  .ctk-more-btn-div {margin-bottom: 2em !important;}
  .ctk-home-tab .v-item-group, .ctk-more-btn-div { background: none !important; }
  .ctk-news-publish{background: #CCC;}
  .ctk-news-publish:hover {background: #076DB1;}
  .ctk-news-publish-enter {background: #076DB1;}
  .ctk-div-news {background: #F8F8F8 !important; }
  .ctk-div-news .v-tabs-bar {background: #F8F8F8 !important;}
  .ctk-about-submenu {background: rgba(255,255,255, 0) !important; border-radius: 0.4em !important;}
  .ctk-about-submenu .v-tabs {background: rgba(255,255,255, 0) !important; border-radius: 0.4em !important;}
  .ctk-about-submenu .v-tabs-bar {
    background: rgba(255,255,255, 0.7) !important;
    cursor: initial;
  }
  .ctk-about-submenu .v-tab--active {background: rgba(255,255,255, 0) !important; color: #076DB1 !important; font-size:14px !important; border-radius: 0.2em;}
  .ctk-about-submenu .v-tabs-slider {background:none !important;}
  /*
  .ctk-timeline .v-timeline-item {padding-bottom: 80px;}
  .ctk-timeline .v-timeline-item__dot {border-radius: 0 !important; width:100px; height:100px;color: #fff; font-size:32px;}
  .ctk-timeline .v-timeline-item__inner-dot {border-radius: 0 !important;}
  .ctk-timeline .v-timeline-item__body {margin-left: 40px;}
  .ctk-timeline:before { background: rgba(0,128,230, 0.3) !important; width: 8px !important; }
  */
  .v-pagination {background: #F8F8F8 !important;}
  .v-pagination button {background: #F8F8F8 !important;}
  .v-pagination button, .v-pagination button:focus { outline: none; border-color: transparent; box-shadow:none; }
  .v-pagination__navigation .mdi-chevron-left,.v-pagination__navigation .mdi-chevron-right {background: #F8F8F8 !important;}
  .ctk-news-title {color: #000;}
  .ctk-news-profile {margin: 20px 0; color: #333;}
  .ctk-news-date {position:absolute; bottom: 20px; color: #333;}
  .ctk-customer-logo { max-height: 100px; box-shadow: 2px 2px 5px #888; cursor: pointer; transition:all 0.25s ease; z-index:0; }
  .ctk-customer-logo:hover { box-shadow: none; border: 1px solid #ccc; transform: scale(2); z-index:1; }
  .ctk-case-category-img { max-height:200px; }
  /*.ctk-case-category-card  {background:#f8f8f8 !important;}*/
  .ctk-case-category-hover {color:#fff !important;}
  .ctk-case-category-card:hover  {background:#076DB1 !important;}
  .v-overlay__content {width:100%; overflow: hidden; }
  /*.ctk-select.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
  }
  .ctk-select.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
  }*/
  .ctk-qa {background:#f8f8f8 !important;}
  .ctk-qa .v-expansion-panel:before {box-shadow: none !important;}
  .ctk-qa .v-expansion-panel-header {border: none !important;}
  .v-application .elevation-2 {
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1) !important;
  }
  .v-list--dense .v-subheader {
    padding: 0 60px;
    color: #333 !important;
    font-size: 1em;
  }
  .top-banner-home { height: 530px !important; }
  .top-banner { height: 420px !important; }
  @media(max-width:960px){
    .top-banner-home { height: 320px !important; }
    .top-banner { height: 280px !important; }
    .ctk-div {padding-top: 60px !important;}
  }
  @media(max-width:600px){
    .top-banner-home { height: 220px !important; }
    .top-banner { height: 200px !important; }
    .ctk-div {padding-top: 30px !important;}
  }
</style>
