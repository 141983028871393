import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: { title: '公司介绍', keywords: '', description: '' }
    },
    {
      path: '/news/:type(\\d+)?',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '新闻', keywords: '', description: '' }
    },
    {
      path: '/news/detail/:id(\\d+)',
      name: 'news',
      component: () => import('./views/New.vue'),
      meta: { title: '新闻详情', keywords: '', description: '' }
    },
    {
      path: '/products/:pid(\\d+)?',
      name: 'products',
      component: () => import('./views/Products.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/products/:pid(\\d+)/:id(\\d+)',
      name: 'products',
      component: () => import('./views/Products2.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/products/detail/:id(\\d+)',
      name: 'products',
      component: () => import('./views/ProductDetail.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/product-znm',
      name: 'product-znm',
      component: () => import('./views/ProductZnm.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/cases/:pid(\\d+)?',
      name: 'cases',
      component: () => import('./views/Cases.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/cases/:pid(\\d+)/:id(\\d+)',
      name: 'cases',
      component: () => import('./views/Cases2.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/cases/detail/:id(\\d+)',
      name: 'cases',
      component: () => import('./views/CaseDetail.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/service',
      name: 'service',
      component: () => import('./views/Service.vue'),
      meta: { title: '服务与支持', keywords: '', description: '' }
    },
    {
      path: '/service/color/:src(\\w+)?',
      name: 'service/color',
      component: () => import('./views/Color.vue'),
      meta: { title: '色彩之旅', keywords: '', description: '' }
    },
    {
      path: '/service/detail',
      name: 'service/detail',
      component: () => import('./views/ServiceDetail.vue'),
      meta: { title: '企业服务', keywords: '', description: '' }
    },
    {
      path: '/service/qa/:id(\\d+)?',
      name: 'service/qa',
      component: () => import('./views/Qa.vue'),
      meta: { title: 'Q&A', keywords: '', description: '' }
    },
    {
      path: '/contact/:pid(\\d+)?',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: { title: '联系我们', keywords: '', description: '' }
    },
    {
      path: '*',
      redirect: { name: 'home' }
    }
  ]
})
